<template>
  <div class="property-fee">
    <van-list 
      v-model="loading" 
      :finished="finished" 
      finished-text="没有更多了"
      @load="getFeeList">
      <fee-item v-for="item in list" :key="item.id" :item="item"></fee-item>
    </van-list>
  </div>
</template>

<script>
import FeeItem from './FeeItem.vue'
import { getList } from '@/api/propertyApi'

export default {
  name: 'PropertyFee',
  components: {
    FeeItem,
  },
  created() {
    // this.getFeeList()
  },
  data() {
    return {
      loading: false,
      finished: false,
      list: [],
      openId: JSON.parse(localStorage.getItem('yt-openId')).content,
      userInfo: this.$store.getters.userInfo,
    }
  },
  methods: {
    // 获取缴费列表
    async getFeeList() {
      const { openId } = this
      const { estateId } = this.userInfo
      const res = await getList({
        openId,
        estateId,
      })

      if (res.code == 200) {
        this.loading = false
        this.finished = true
        this.list = res.data
      }

    },
  },
}
</script>

<style lang="scss" scoped>
.property-fee {
  padding: 10px;
  margin-top: 12px;
}
</style>
