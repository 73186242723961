<template>
    <div class="diy-cell" @click="showDetail">
      <div class="top fx_ai">
        <div class="title van-ellipsis fx1">{{ item.estateName }}</div>
        <!-- <van-tag
          size="mini"
          :color="item.status == '1' ? '#FBE7E6' : '#DAF5DC'"
          :text-color="item.status == '1' ? '#E35D58' : '#0DB918'"
          >{{ item.status == '1' ? '已结束' : '进行中' }}</van-tag
        > -->
      </div>
      
      <p>小区房屋：{{ location(item) }}</p>
      <p>应交金额：<span class="price">{{ Number(item.totalCost).toFixed(2) }}元</span></p>
      <van-divider style="margin: 0.21rem 0" />
      <div style="text-align: right">
        <!-- <van-button style="padding: 0.32rem" type="info" size="mini" @click.stop="goRecordPayment">缴纳记录</van-button> -->
        <van-button style="padding: 0.32rem" type="info" size="mini" @click.stop="payment(item.totalCost)">缴纳</van-button>

      </div>
    </div>

    <!-- <div class="fee-item" @click="showDetail">
      <h2 class="name">{{ item.estateName }}</h2>
      <van-cell-group class="cell-group">
        <van-cell title="小区房屋">
          <template #right-icon>
            <span class="address">{{ location(item) }}</span>
          </template>
        </van-cell>
        <van-cell title="应交金额">
          <template #right-icon>
            <span class="price">{{ Number(item.totalCost).toFixed(2) }}元</span>
          </template>
        </van-cell>
      </van-cell-group>
      <div class="btn-group">
        <van-button type="info" size="small" class="fee-info" @click.stop="goRecordPayment">缴纳记录</van-button>
        <van-button type="info" size="small" class="fee" @click.stop="payment(item.totalCost)">缴纳</van-button>
      </div>
    </div> -->
</template>

<script>
// import { Cell, CellGroup } from 'vant';
import { pay } from '@/api/propertyApi'
import { Notify } from 'vant'
// import wx from 'weixin-jsapi'

export default {
  props: {
    item: Object,
  },
  name: 'FeeItem',
  data() {
    return {}
  },
  computed: {
    location() {
      return item => {
        // 拼接小区房屋
        return `${item.estateName}${item.buildingName}${item.unitName}${item.roomName}`
      }
    },
  },
  methods: {
    // 物业缴费详情
    showDetail() {
      const { id } = this.item
      this.$router.push('/propertyFeeDetail?id=' + id)
    },
    // 查看缴纳记录
    goRecordPayment() {
      const { roomId } = this.item
      this.$router.push('/paymentRecords?roomId=' + roomId)
    },
    // 缴纳
    async payment(totalCost) {
      const { id } = this.item
      if (this.isWeiXin()) {
        this.$store.commit('SET_IS_LOADING', true)
        const res = await pay(
          {
            acct: JSON.parse(localStorage.getItem('yt-openId')).content, // 用户openid
            paytype: 'W01', // 交易方式
            body: '', // 主题内容
            remark: '', // 备注
          },
          {
            id,
            totalCost,
            paymentStatus: 0, // 缴费状态
          }
        )
        this.$store.commit('SET_IS_LOADING', false)

        if (res.code == 200) {
          let data = res.data
          const { payinfo } = data
          if (!payinfo) {
            return this.$toast({
              type: 'fail',
              message: data.errmsg ? data.errmsg : '支付失败',
              duration: 1000,
            })
          }
          window.location.href = payinfo
          window.location.href = payinfo
          // let payinfoObj = JSON.parse(payinfo);
          // const {
          //     appId,
          //     timeStamp,
          //     nonceStr,
          //     package: pac,
          //     signType,
          //     paySign,
          // } = payinfoObj;
          // console.log(payinfoObj);
          // wx.config({
          //     debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
          //     appId: appId, // 必填，公众号的唯一标识
          //     timestamp: timeStamp, // 必填，生成签名的时间戳
          //     nonceStr: nonceStr, // 必填，生成签名的随机串
          //     signature: 'asda',// 必填，签名
          //     jsApiList: ['chooseWXPay'] // 必填，需要使用的 JS 接口列表
          // });

          // wx.chooseWXPay({
          //     appId: appId,
          //     timestamp: timeStamp, // 支付签名时间戳，注意微信 jssdk 中的所有使用 timestamp 字段均为小写。但最新版的支付后台生成签名使用的 timeStamp 字段名需大写其中的 S 字符
          //     nonceStr: nonceStr, // 支付签名随机串，不长于 32 位
          //     package: pac, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          //     signType: signType, // 微信支付V3的传入 RSA ,微信支付V2的传入格式与V2统一下单的签名格式保持一致
          //     paySign: paySign, // 支付签名
          //     success: function (res) {
          //         // 支付成功后的回调函数
          //         console.log(res);
          //         // 支付成功跳转成功页面
          //         this.$router.replace({
          //             path: '/paySuccess'
          //         });
          //     }
          // });
        }
      } else {
        // 警告通知
        Notify({ type: 'warning', message: '请使用微信浏览器打开页面' })
      }
    },
    // 判断是否是微信浏览器
    isWeiXin() {
      var ua = window.navigator.userAgent.toLowerCase()

      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true
      } else {
        return false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
// .fee-item {
//   background-color: #fff;
//   margin-top: 10px;
//   .name {
//     font-size: 16px;
//     padding: 10px;
//   }

//   .cell-group {
//     .address {
//       color: #969799;
//     }

//     .price {
//       color: #fb001e;
//     }
//   }

//   .btn-group {
//     text-align: right;
//     padding-bottom: 10px;
//     margin-right: 10px;

//     .fee-info {
//       margin-right: 20px;
//     }
//   }
// }
.diy-cell{
  margin: 0 15px 12px ;
  border-radius: 12px;
  padding: .53rem;
  padding-bottom: .21rem;
  background: #fff;
  font-size: .37rem;
  .top{
    margin-bottom: 8px;
    .title{
      font-weight: bold;
    }
  }
  .time{
    font-size: .32rem;
    color: #646466;
    margin-bottom: 8px;
  }
  .price {
    color: #fb001e;
  }
  .van-button {
    border-radius: 4px;
  }
}
</style>
